import React from 'react';

import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

const Header = class extends React.Component {
	constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: '',
    }
	}

	toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
				// set the class in state for the navbar accordingly
				this.state.active
					? this.setState({
						navBarActiveClass: 'is-active',
					})
					: this.setState({
						navBarActiveClass: '',
					})
      }
    )
	}

	render() {
		return (
			<header className={`header l-header ${this.state.navBarActiveClass}`}>
				<div
					className="l-header__trigger trigger"
					onClick={() => this.toggleHamburger()}
					onKeyDown={() => this.toggleHamburger()}
					role="button"
					tabIndex={0}
				>
					<div className="trigger__inner">
						<span className="trigger__item"></span>
						<span className="trigger__item"></span>
						<span className="trigger__item"></span>
					</div>
				</div>
				<h1 className="l-header__logo logo">
					<Link to="/">
						<span className="logo__img">
							<StaticImage
								src="../assets/images/logo.png"
								alt="名言アドバイザー MEIGEN ADVISER"
							/>
						</span>
						<span className="logo__text">あなたの人生に名言を。</span>
					</Link>
				</h1>

				{/* <div className="l-header__search"></div> */}

				<div className="l-header__content header-content">
					<div className="header-content__inner">
						<ul className="header-content__links">
							<li className="header-content__link">
								<Link to="/">
									<span className="header-content__text header-content__text--num">1</span>
									<span className="header-content__text header-content__text--en">home</span>
									<span className="header-content__text header-content__text--ja">トップページ</span>
								</Link>
							</li>
							<li className="header-content__link">
								<Link to="/quote/">
									<span className="header-content__text header-content__text--num">2</span>
									<span className="header-content__text header-content__text--en">new</span>
									<span className="header-content__text header-content__text--ja">新着名言</span>
								</Link>
							</li>
							<li className="header-content__link">
								<Link to="/person/">
									<span className="header-content__text header-content__text--num">3</span>
									<span className="header-content__text header-content__text--en">person</span>
									<span className="header-content__text header-content__text--ja">偉人から探す</span>
								</Link>
							</li>
						</ul>
					</div>
				</div>
				<div
					className="l-header__overlay"
					onClick={() => this.toggleHamburger()}
					onKeyDown={() => this.toggleHamburger()}
					role="button"
					tabIndex={0}
					aria-label="trigger"
				></div>
			</header>
		)
	}
}

export default Header;