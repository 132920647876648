import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const Seo = props => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          lang
          description
          siteUrl
          locale
          fbappid
          image
        }
      }
    }
  `)

  const title = props.pagetitle
    ? `${props.pagetitle} | ${data.site.siteMetadata.title}`
    : data.site.siteMetadata.title

  const description = props.pagedesc || data.site.siteMetadata.description

  const url = props.pagepath
    ? `${data.site.siteMetadata.siteUrl}${props.pagepath}`
    : data.site.siteMetadata.siteUrl

  const imgurl = props.pageimg
    ? `${props.pageimg}`
    : props.blogimg || `${data.site.siteMetadata.image}`
  const imgw = props.pageimgw || 1280
  const imgh = props.pageimgh || 640
  return (
    <Helmet>
      <html lang={data.site.siteMetadata.lang} />
      <title>{title}</title>
      <meta name="description" content={description} />

      <link rel="canonical" href={url} />
      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2773583607978385" crossorigin="anonymous"></script>

      <meta property="og:site_name" content={data.site.siteMetadata.title} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content={data.site.siteMetadata.locale} />
      <meta property="fb:app_id" content={data.site.siteMetadata.fbappid} />

      <meta property="og:image" content={imgurl} />
      <meta property="og:image:width" content={imgw} />
      <meta property="og:image:height" content={imgh} />
      <meta name="twitter:card" content="summary_large_image" />

      {/* <link rel="shortcut icon" href={`/${data.site.siteMetadata.siteUrl}/assets/images/favicon.ico`} type="image/x-icon" />
      <link rel="apple-touch-icon" href={`${data.site.siteMetadata.siteUrl}/assets/images/apple-touch-icon.png`} />
      <link rel="apple-touch-icon" sizes="57x57" href={`${data.site.siteMetadata.siteUrl}/assets/images/apple-touch-icon-57x57.png`} />
      <link rel="apple-touch-icon" sizes="72x72" href={`${data.site.siteMetadata.siteUrl}/assets/images/apple-touch-icon-72x72.png`} />
      <link rel="apple-touch-icon" sizes="76x76" href={`${data.site.siteMetadata.siteUrl}/assets/images/apple-touch-icon-76x76.png`} />
      <link rel="apple-touch-icon" sizes="114x114" href={`${data.site.siteMetadata.siteUrl}/assets/images/apple-touch-icon-114x114.png`} />
      <link rel="apple-touch-icon" sizes="120x120" href={`${data.site.siteMetadata.siteUrl}/assets/images/apple-touch-icon-120x120.png`} />
      <link rel="apple-touch-icon" sizes="144x144" href={`${data.site.siteMetadata.siteUrl}/assets/images/apple-touch-icon-144x144.png`} />
      <link rel="apple-touch-icon" sizes="152x152" href={`${data.site.siteMetadata.siteUrl}/assets/images/apple-touch-icon-152x152.png`} />
      <link rel="apple-touch-icon" sizes="180x180" href={`${data.site.siteMetadata.siteUrl}/assets/images/apple-touch-icon-180x180.png`} /> */}
    </Helmet>
  )
}

export default Seo